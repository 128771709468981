import { environment as devEnv } from './environment.dev'
import { environment as prodEnv } from './environment.prod'
import { environment as sandboxEnv } from './environment.sandbox'
import { IEnv } from './env.type'

const getEnv = (): IEnv => {
  console.log(`========================\n${process.env.REACT_APP_STAGE}\n========================\n`)
  switch (process.env.REACT_APP_STAGE) {
    case 'production':
      return prodEnv
    case 'sandbox':
      return sandboxEnv
    case 'development':
    default:
      return devEnv
  }
}

export const environment = getEnv()
